import { chain } from 'icepick';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import type { IndexItems } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { IssueWithPos } from '@atlassian/jira-servicedesk-queues-common/src/services/issue/transform/types.tsx';
import type { CollectionItem } from '../../../../model/index.tsx';
import type { Issues } from './types.tsx';

export const DEFAULT_ISSUES_STATE = {
	collection: [],
	index: {},
	totalCount: 0,
	timeWhenLastInitialized: 0,
	isUsingDefaultSorting: false,
};

const updateCollection = (
	collection: CollectionItem[],
	index: IndexItems,
	issue: IssueWithPos,
	startIndex: number,
): CollectionItem[] => {
	const { key } = issue;
	const position = issue.position + startIndex;
	const newCollection = collection;
	// Find where the issue used to live in the collection, and delete if it exists
	if (index[key] != null) {
		const oldPosition = index[key];
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		if (newCollection[oldPosition] != null && newCollection[oldPosition].key === key) {
			delete newCollection[oldPosition];
		}
	}
	newCollection[position] = omit(issue, 'position');
	return newCollection;
};

const updateIndex = (index: IndexItems, issue: IssueWithPos, startIndex: number): IndexItems => {
	const { key } = issue;
	const position = startIndex + issue.position;
	const newIndex = index;
	newIndex[key] = position;
	return newIndex;
};

export const addIssuesResponseToState = (
	startIndex: number,
	totalCount: number,
	isUsingDefaultSorting: boolean,
	issues: IssueWithPos[],
	state: Issues = DEFAULT_ISSUES_STATE,
) => {
	// Slice as any issues in collection that exist beyond totalCount need to be deleted
	const collection = state.collection.slice(0, totalCount);
	const index = { ...state.index };

	issues.forEach((newIssue) => {
		updateCollection(collection, index, newIssue, startIndex);
		updateIndex(index, newIssue, startIndex);
	});

	if (
		state.totalCount === totalCount &&
		state.isUsingDefaultSorting === isUsingDefaultSorting &&
		isEqual(state.index, index) &&
		isEqual(state.collection, collection)
	) {
		return state;
	}

	return chain(state)
		.assoc('collection', collection)
		.assoc('index', index)
		.assoc('totalCount', totalCount)
		.assoc('isUsingDefaultSorting', isUsingDefaultSorting)
		.value();
};
