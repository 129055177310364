import React from 'react';
import memoize from 'lodash/memoize';
import { FlagsBoundary } from '@atlassian/jira-flag-boundary/src/FlagsBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type * as FlagsModuleType from './index.tsx';
import type { State } from './services/store/index.tsx';
import {
	DISMISS_COMMAND,
	type Action,
	type FlagConfiguration,
	type FlagId,
	type FlagsDispatcherFactoryProps,
	type FlagsMapper,
	type FlagsMapperFactory,
	type FlagsMapperFactoryArguments,
} from './services/types.tsx';
import type { FlagsDispatcherProps } from './ui/dispatcher/index.tsx';

const createFlagsDispatcherImpl = <T extends Action, S extends State>(
	mapperFactory: FlagsMapperFactory<T, S>,
	props: Flow.Diff<
		FlagsDispatcherProps<T, S>,
		{
			mapper: FlagsMapper<T, S>;
		}
	>,
	{ FlagsDispatcher, toFlagId, dismissFlag }: typeof FlagsModuleType,
) => {
	const mapper = mapperFactory({ dismissFlag, toFlagId });
	return <FlagsDispatcher {...props} mapper={mapper} />;
};

// We create flags mappers dynamically, in part, to allow a closure to be established.
// This is memoized to retain any state provided via a closure.
const createFlagsDispatcher: typeof createFlagsDispatcherImpl = memoize(createFlagsDispatcherImpl);

type AsyncFlagsDispatcherProps<T extends Action, S> =
	| FlagsDispatcherProps<T, S>
	| FlagsDispatcherFactoryProps<T, S>;

const getFlagsDispatcher = <T extends Action, S extends State>(
	module: typeof FlagsModuleType,
	props: AsyncFlagsDispatcherProps<T, S>,
) => {
	if ('mapperFactory' in props) {
		const { mapperFactory, ...dispatcherProps } = props;
		const flagsDispatcher = createFlagsDispatcher(mapperFactory, dispatcherProps, module);
		return flagsDispatcher;
	}
	const { FlagsDispatcher } = module;
	return <FlagsDispatcher {...props} />;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyFlagsDispatcher = lazy(() =>
	import(/* webpackChunkName: "async-flags" */ './index').then(
		(module) =>
			<T extends Action, S extends State>(props: AsyncFlagsDispatcherProps<T, S>) =>
				getFlagsDispatcher(module, props),
	),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AsyncFlagsDispatcher = (props: AsyncFlagsDispatcherProps<any, any>) => (
	<Placeholder name="async-flags-dispatcher" fallback={null}>
		<FlagsBoundary packageName="async-flags-dispatcher">
			<LazyFlagsDispatcher {...props} />
		</FlagsBoundary>
	</Placeholder>
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DISMISS_COMMAND, FlagsBoundary };
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	FlagConfiguration,
	FlagId,
	FlagsMapper,
	FlagsMapperFactory,
	FlagsMapperFactoryArguments,
};
