import React, { useState, type ComponentType } from 'react';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import QueuesBreadcrumbRenderer from '@atlassian/jira-servicedesk-queues-breadcrumb/src/ui/queues-breadcrumb-renderer/index.tsx';
import { FFErrorBoundary } from '../../../common/ff-error-boundary/index.tsx';

export type Props = {
	queueName: string;
	Actions: ComponentType<{ onDeleteComplete: (arg1: string) => void }>;
	onDeleteComplete: (arg1: string) => void;
	renderDisplayError: () => void;
};

export default function QueuesPageHeader(props: Props) {
	const [, setAddTopMargin] = useState(false);

	const { queueName, onDeleteComplete, renderDisplayError, Actions } = props;

	const handleError = (location?: string) => {
		if (location === 'common.error-boundary.queue-page-header') {
			setAddTopMargin(true);
		}
		renderDisplayError();
	};

	const shouldHideActions = __SERVER__ && fg('issue_list_ssr_hide_top_right_icons');

	return (
		<FFErrorBoundary onError={handleError} id="queue-page-header">
			<PageHeader
				truncateTitle
				// hide icons before hydration to disable interactive actions
				actions={shouldHideActions ? undefined : <Actions onDeleteComplete={onDeleteComplete} />}
				breadcrumbs={<QueuesBreadcrumbRenderer />}
			>
				{queueName}
			</PageHeader>
		</FFErrorBoundary>
	);
}
