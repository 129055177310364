import type { FlagsMapper } from '@atlassian/jira-flags/src/async'; // ignore-for-ENGHEALTH-17759
import {
	FIELD_UPDATE_FAILURE,
	type UpdateFieldFailureAction,
} from '../state/actions/field/update/index.tsx';
import { type DisableUserPickerAction, DISABLE_USER_PICKER } from '../state/actions/ui/index.tsx';
import type { State } from '../state/reducers/types.tsx';
import userPickerDisabled from './field/assignee/error/index.tsx';
import fieldUpdateFailure from './field/update/error/index.tsx';

type Action = UpdateFieldFailureAction | DisableUserPickerAction;

const decodeFailure = (stringThatMayBeJson: string): string | undefined => {
	try {
		const obj = JSON.parse(stringThatMayBeJson);
		if (obj.errorMessages && obj.errorMessages[0]) {
			return obj.errorMessages[0];
		}
		if (obj.errors) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			return Object.values(obj.errors)[0] as any;
		}
		return undefined;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return stringThatMayBeJson;
	}
};

// @ts-expect-error - TS7006 - Parameter 'failureReason' implicitly has an 'any' type.
const isPresentableFailureReason = (failureReason) =>
	failureReason.statusCode &&
	failureReason.message &&
	failureReason.message.indexOf(`: ${failureReason.statusCode}`) === -1;

const flagsMapper: FlagsMapper<Action, State> = (action) => {
	switch (action.type) {
		case FIELD_UPDATE_FAILURE: {
			const { fieldName, issueKey, failureReason } = action.payload;
			return fieldUpdateFailure({
				fieldName,
				issueKey,
				reasonMessage:
					failureReason.message && isPresentableFailureReason(failureReason)
						? decodeFailure(failureReason.message)
						: undefined,
			});
		}
		case DISABLE_USER_PICKER: {
			return userPickerDisabled();
		}
		default: {
			const _exhaustiveCheck: never = action;
			return undefined;
		}
	}
};

export default flagsMapper;
