import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import log from '@atlassian/jira-servicedesk-queues-common/src/log/index.tsx';
import { updateQueueIssuesSortingDataUserProperty } from '../../../rest/sort/index.tsx';
import {
	SORT_ORDER_CHANGED,
	UPDATE_SORTING_INFO_IN_USER_PROPERTY,
	type UpdateSortingInfoInUserPropertyAction,
} from '../../../state/actions/table/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getCreateAnalyticsEvent } from '../../../state/selectors/analytic/index.tsx';
import { getQueueId } from '../../../state/selectors/queue/index.tsx';
import { getAccountId } from '../../../state/selectors/user/index.tsx';

const epic = (
	action$: ActionsObservable<Action | UpdateSortingInfoInUserPropertyAction>,
	store: MiddlewareAPI<State>,
) =>
	// remove UPDATE_SORTING_INFO_IN_USER_PROPERTY as part of the ff clean_local_storage_sorting
	action$.ofType(SORT_ORDER_CHANGED, UPDATE_SORTING_INFO_IN_USER_PROPERTY).switchMap((action) => {
		const state = store.getState();
		const queueId = getQueueId(state);
		const manualSorting = action.payload;
		const accountId = getAccountId(state);
		const createAnalyticsEvent = getCreateAnalyticsEvent(state);

		return updateQueueIssuesSortingDataUserProperty(manualSorting, queueId, accountId)
			.switchMap(() => {
				if (createAnalyticsEvent) {
					fireOperationalAnalytics(
						createAnalyticsEvent({}),
						'viewQueueManualSortingToUserProperty updated',
					);
				}
				return Observable.empty<never>();
			})
			.catch((error) => {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'opsUpdateQueueSorting',
						packageName: 'jira-servicedesk-queues-agent-view',
						teamName: 'Flash',
					},
				});

				log.safeErrorWithoutCustomerData(
					'servicedesk.queues.agent-view.ops',
					'User properties failure for update sorting',
				);

				return Observable.empty<never>();
			});
	});

export default epic;
