import React from 'react';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics-component';
import { getErrorHash } from '../../utils/error-hash.tsx';
import { CodeBlockWithCopyButton } from '../code-block-with-actions/CodeBlockWithCopyButton.tsx';

export const ErrorHashAndTraceId = ({ error, traceId }: { error: Error; traceId: string }) => {
	const errorHash = getErrorHash(error);
	const source = `Hash ${errorHash}\nTrace ${traceId}`;

	return (
		<>
			<FireOperationalAnalytics
				action="rendered"
				actionSubject="errorHashAndTraceId"
				attributes={{
					errorHash,
					traceId,
				}}
			/>
			<div role="note">
				<CodeBlockWithCopyButton
					source={source}
					copyCodeActionSubjectId="copyErrorHashAndTraceId"
				/>
			</div>
		</>
	);
};
