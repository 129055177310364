import React, { type ReactNode, type MouseEvent } from 'react';
import Banner from '@atlaskit/banner';
import WarningIcon from '@atlaskit/icon/core/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import messages from './messages.tsx';

function CipherDeprecationBanner() {
	const { formatMessage } = useIntl();
	const isSiteAdmin = useIsSiteAdmin();

	return (
		<JSErrorBoundary
			id="jiraQueueViewCipherDeprecationBanner"
			packageName="jiraSpaAppsServicedeskQueuesAgentView"
			teamName="jsd-shield"
		>
			{isSiteAdmin && (
				<Banner appearance="warning">
					<Box xcss={bannerContainerStyles}>
						<Box>
							<WarningIcon label="Warning" color={token('color.icon.warning.inverse')} />
						</Box>
						<Box>
							{formatMessage(messages.cipherDeprecationMessage, {
								button: (chunks: ReactNode) => (
									<HelpPanelButton
										articleId="6bSBVhywj4oQDe7rCDr9tT"
										appearance="subtle-link"
										spacing="none"
										onClick={(e: MouseEvent<EventTarget>, analyticsEvent: UIAnalyticsEvent) =>
											fireUIAnalytics(analyticsEvent, 'learnMoreAboutLatestEmailCiphersLink')
										}
										fallbackComponent={<>{chunks}</>}
									>
										<Box xcss={linkTextStyles}>{chunks}</Box>
									</HelpPanelButton>
								),
							})}
						</Box>
					</Box>
				</Banner>
			)}
		</JSErrorBoundary>
	);
}

const bannerContainerStyles = xcss({
	display: 'flex',
	columnGap: 'space.100',
});

const linkTextStyles = xcss({
	color: 'color.text.warning.inverse',
	textDecoration: 'underline',
});

export default CipherDeprecationBanner;
