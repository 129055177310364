import React, { Component } from 'react';
import { styled } from '@compiled/react';
import AddCircleIcon from '@atlaskit/icon/core/migration/add--add-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type IntlShape, defineMessages, injectIntl } from '@atlassian/jira-intl';
import type { RowAddCallback } from '../../../../../../../model/callbacks/index.tsx';
import type { Optional } from '../../../../../../../model/optional/index.tsx';
import type { RowId, RelativePosition } from '../../../../../../../model/rows/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const IconWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: token('space.300', '24px'),
	width: token('space.300', '24px'),
	marginTop: token('space.negative.150', '-12px'),
	marginLeft: token('space.negative.025', '-2px'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Wrapper = styled.div<{ isIndented: boolean }>({
	boxSizing: 'border-box',
	height: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.green.subtle', colors.G200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border.inverse', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border.inverse', colors.N0)}`,
	opacity: 0,
	cursor: 'pointer',
	position: 'relative',
	top: token('space.025', '2px'),
	marginTop: token('space.negative.050', '-4px'),
	'&:hover': {
		opacity: 1,
		transition: 'opacity 0.1s ease-in',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ isIndented }) =>
		isIndented ? token('space.negative.200', '-16px') : token('space.negative.150', '-12px'),
});

const messages = defineMessages({
	addIcon: {
		id: 'virtual-table.table.content.rows.common.default-row.add-bar.add-icon',
		defaultMessage: 'Click to add row',
		description: '',
	},
});

type Props = {
	rowId: RowId;
	addPosition: RelativePosition;
	isRowExpanded: boolean;
	canAddChildren: boolean;
	canAddSibling: boolean;
	onRowAddRequested: Optional<RowAddCallback>;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class AddBar extends Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		const { rowId, isRowExpanded, canAddChildren, canAddSibling } = this.props;

		return (
			rowId !== nextProps.rowId ||
			canAddChildren !== nextProps.canAddChildren ||
			canAddSibling !== nextProps.canAddSibling ||
			isRowExpanded !== nextProps.isRowExpanded
		);
	}

	onRowAddRequested = () => {
		const { rowId, addPosition, onRowAddRequested } = this.props;

		onRowAddRequested &&
			onRowAddRequested({
				anchorId: rowId,
				position: addPosition,
			});
	};

	renderAddBar() {
		const {
			intl: { formatMessage },
			isRowExpanded,
			rowId,
		} = this.props;

		return (
			<Wrapper
				isIndented={isRowExpanded}
				onClick={this.onRowAddRequested}
				// eslint-disable-next-line jira/integration/enforce-data-testid-usage
				data-test-id={`common.components.virtual-table.table.content.rows.common.default-row.add-bar.wrapper-${rowId}`}
			>
				<IconWrapper>
					<AddCircleIcon
						color={token('color.icon.accent.green', colors.G200)}
						label={formatMessage(messages.addIcon)}
					/>
				</IconWrapper>
			</Wrapper>
		);
	}

	render() {
		const { canAddChildren, canAddSibling, addPosition } = this.props;

		const canAddAtPosition =
			(addPosition === 'INSIDE' && canAddChildren) || (addPosition === 'AFTER' && canAddSibling);

		return canAddAtPosition ? this.renderAddBar() : null;
	}
}

export default injectIntl(AddBar);
