import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { SortOrder } from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { ScrollDirection } from '../../../model/index.tsx';

// TABLE_CHANGED

export const TABLE_CHANGED = 'state.actions.table.TABLE_CHANGED' as const;

export type TableChangedAction = {
	type: typeof TABLE_CHANGED;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const tableChangedAction = (analyticsEvent: UIAnalyticsEvent): TableChangedAction => ({
	type: TABLE_CHANGED,
	meta: {
		analyticsEvent,
	},
});

// ACTIVE_ITEM_CHANGED

export const ACTIVE_ITEM_CHANGED = 'state.actions.table.ACTIVE_ITEM_CHANGED' as const;

export type ActiveItemChangedAction = {
	type: typeof ACTIVE_ITEM_CHANGED;
	payload: {
		rowId: string;
		colId: string;
	};
};

export const activeItemChangedAction = (rowId: string, colId: string): ActiveItemChangedAction => ({
	type: ACTIVE_ITEM_CHANGED,
	payload: {
		rowId,
		colId,
	},
});

// ACTIVE_ITEM_REMOVED

export const ACTIVE_ITEM_REMOVED = 'state.actions.table.ACTIVE_ITEM_REMOVED' as const;

export type ActiveItemRemovedAction = {
	type: typeof ACTIVE_ITEM_REMOVED;
};

export const activeItemRemovedAction = (): ActiveItemRemovedAction => ({
	type: ACTIVE_ITEM_REMOVED,
});

// PAGE_CHANGED

export const PAGE_CHANGED = 'state.actions.table.PAGE_CHANGED' as const;

export type PageChangedAction = {
	type: typeof PAGE_CHANGED;
	payload: {
		currentPage: number;
	};
};

export const pageChangedAction = (currentPage: number): PageChangedAction => ({
	type: PAGE_CHANGED,
	payload: {
		currentPage,
	},
});

// SORT_ORDER_CHANGED

export const SORT_ORDER_CHANGED = 'state.actions.table.SORT_ORDER_CHANGED' as const;

export type SortOrderChangedAction = {
	type: typeof SORT_ORDER_CHANGED;
	payload: {
		sortedBy: string;
		sortOrder: SortOrder;
	};
};

export const sortOrderChangedAction = (
	sortedBy: string,
	sortOrder: SortOrder,
): SortOrderChangedAction => ({
	type: SORT_ORDER_CHANGED,
	payload: {
		sortedBy,
		sortOrder,
	},
});

export const SORT_ORDER_OPTIMISTIC_UPDATE =
	'state.actions.table.SORT_ORDER_OPTIMISTIC_UPDATE' as const;

export type SortOrderOptimisticUpdateAction = {
	type: typeof SORT_ORDER_OPTIMISTIC_UPDATE;
	payload: {
		queueId: QueueId;
		sortedBy: string;
		sortOrder: SortOrder;
	};
};

export const sortOrderOptimisticUpdateAction = (
	queueId: QueueId,
	sortedBy: string,
	sortOrder: SortOrder,
): SortOrderOptimisticUpdateAction => ({
	type: SORT_ORDER_OPTIMISTIC_UPDATE,
	payload: {
		queueId,
		sortedBy,
		sortOrder,
	},
});

// SORT_ORDER_ON_LOAD

export const SORT_ORDER_ON_LOAD = 'state.actions.table.SORT_ORDER_ON_LOAD' as const;

export type SortOrderOnLoadAction = {
	type: typeof SORT_ORDER_ON_LOAD;
	payload: {
		sortedBy: string;
		sortOrder: SortOrder;
	};
};

export const sortOrderOnLoadAction = (
	sortedBy: string,
	sortOrder: SortOrder,
): SortOrderOnLoadAction => ({
	type: SORT_ORDER_ON_LOAD,
	payload: {
		sortedBy,
		sortOrder,
	},
});

// RESET_SORT_ORDER

export const RESET_SORT_ORDER = 'state.actions.table.RESET_SORT_ORDER' as const;

export type ResetSortOrderAction = {
	type: typeof RESET_SORT_ORDER;
};

export const resetSortOrderAction = (): ResetSortOrderAction => ({
	type: RESET_SORT_ORDER,
});

// INFINITE_SCROLLING

// toggle queue header on vertical scroll change
export const TOGGLE_QUEUE_HEADER = 'state.actions.table.TOGGLE_QUEUE_HEADER' as const;

export type ToggleQueueHeaderAction = {
	type: typeof TOGGLE_QUEUE_HEADER;
	payload: ScrollDirection;
};

export const toggleQueueHeaderAction = (
	scrollDirection: ScrollDirection,
): ToggleQueueHeaderAction => ({
	type: TOGGLE_QUEUE_HEADER,
	payload: scrollDirection,
});

// Fetch data on scroll

export const FETCH_ISSUE_DATA_ONSCROLL = 'state.actions.table.FETCH_ISSUE_DATA_ONSCROLL' as const;

export type FetchIssueDataOnScrollAction = {
	type: typeof FETCH_ISSUE_DATA_ONSCROLL;
	payload: number;
};

export const fetchIssueDataOnScrollAction = (startIndex: number): FetchIssueDataOnScrollAction => ({
	type: FETCH_ISSUE_DATA_ONSCROLL,
	payload: startIndex,
});

// Show flag on fetch error while loading a queue

export const DISPLAY_UNSUCCESSFUL_FETCH_WARNING =
	'state.actions.table.DISPLAY_UNSUCCESSFUL_FETCH_WARNING' as const;

export type DisplayUnsuccessfulFetchWarningAction = {
	type: typeof DISPLAY_UNSUCCESSFUL_FETCH_WARNING;
};

export const displayUnsuccessfulFetchWarningAction = (): DisplayUnsuccessfulFetchWarningAction => ({
	type: DISPLAY_UNSUCCESSFUL_FETCH_WARNING,
});

// Show flag on fetch error during scroll

export const DISPLAY_UNSUCCESSFUL_FETCH_ERROR =
	'state.actions.table.DISPLAY_UNSUCCESSFUL_FETCH_ERROR' as const;

export type DisplayUnsuccessfulFetchErrorOnScrollAction = {
	type: typeof DISPLAY_UNSUCCESSFUL_FETCH_ERROR;
};

export const displayUnsuccessfulFetchErrorOnScrollAction =
	(): DisplayUnsuccessfulFetchErrorOnScrollAction => ({
		type: DISPLAY_UNSUCCESSFUL_FETCH_ERROR,
	});

// Save sorting info retrieved from local storage to user property in initial load
// clean this up as part of the ff clean_local_storage_sorting

export const UPDATE_SORTING_INFO_IN_USER_PROPERTY =
	'state.actions.table.UPDATE_SORTING_INFO_IN_USER_PROPERTY';

export type UpdateSortingInfoInUserPropertyAction = {
	type: typeof UPDATE_SORTING_INFO_IN_USER_PROPERTY;
	payload: {
		sortedBy: string;
		sortOrder: SortOrder;
	};
};

export const updateSortingInfoInUserPropertyAction = (sortedBy: string, sortOrder: SortOrder) => ({
	type: UPDATE_SORTING_INFO_IN_USER_PROPERTY,
	payload: {
		sortedBy,
		sortOrder,
	},
});

export type Action =
	| TableChangedAction
	| ActiveItemChangedAction
	| ActiveItemRemovedAction
	| PageChangedAction
	| SortOrderChangedAction
	| SortOrderOptimisticUpdateAction
	| SortOrderOnLoadAction
	| ResetSortOrderAction
	| ToggleQueueHeaderAction
	| FetchIssueDataOnScrollAction
	| DisplayUnsuccessfulFetchWarningAction
	| DisplayUnsuccessfulFetchErrorOnScrollAction;
