import type { FlagConfiguration, FlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import messages from './message.tsx';

const reload = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.reload();
};

export const unsuccessfulFetchFlagConfig = (id: FlagId): FlagConfiguration => ({
	id,
	type: 'warning',
	title: messages.warningTitle,
	description: messages.warningDescription,
	appearance: 'warning',
	actions: [
		{
			content: messages.tryAgainButton,
			onClick: reload,
		},
	],
});

export const unsuccessfulFetchErrorFlagConfig = (id: FlagId): FlagConfiguration => ({
	id,
	type: 'error',
	title: messages.errorTitle,
	description: messages.errorOnScrollDescription,
	appearance: 'error',
});

export const unsuccessfulPollFlagConfig = (id: FlagId): FlagConfiguration => ({
	id,
	type: 'warning',
	title: messages.warningTitle,
	description: messages.warningDescription,
	appearance: 'warning',
});

export const deleteQueueErrorConfig = (queueName: string): FlagConfiguration => ({
	type: 'error',
	title: messages.queueDeleteFlagErrorTitle,
	description: [messages.queueDeleteFlagErrorDescription, { queueName }],
});
