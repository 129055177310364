import pick from 'lodash/pick';
import {
	SET_VERTICAL_SCROLL_OFFSET,
	SET_VERTICAL_SCROLL_DELTA,
	SET_HORIZONTAL_SCROLL_OFFSET,
	SET_TABLE_SIZE,
	SET_MULTI_ADD_ANCHOR,
	CLEAR_MULTI_ADD_ANCHOR,
	SET_ACTIVE_ITEM,
	CLEAR_ACTIVE_ITEM,
	SET_RESIZING_COLUMN,
	SET_COLUMN_SORT_ORDER,
	SET_SORT_ORDER_WITH_OVERRIDE,
	SET_SORTED_ROW_IDS_HASH,
	SET_ROW_BORDER_WIDTH,
	SET_ROW_HEIGHT_MAPPING,
	BATCH_UPDATE_ROW_HEIGHT_MAPPING,
	SET_SCROLLBAR_SIZE,
	SET_HEADER_HEIGHT,
	SET_RENDER_SIDE_BAR_ICON,
	type SetHorizontalScrollOffsetAction,
	type SetTableSizeAction,
	type SetVerticalScrollOffsetAction,
	type SetVerticalScrollDeltaAction,
	type SetMultiAddAnchorAction,
	type ClearMultiAddAnchorAction,
	type SetActiveItemAction,
	type ClearActiveItemAction,
	type SetResizingColumnAction,
	type SetColumnSortOrderAction,
	type SetSortOrderWithOverrideAction,
	type SetSortedRowIdsHashAction,
	type SetRowBorderWidthAction,
	type SetRowHeightMappingAction,
	type BatchUpdateRowHeightMappingAction,
	type SetScrollbarSizeAction,
	type SetHeaderHeightAction,
	type SetRenderSidebarIconAction,
} from './actions.tsx';
import type { InternalState } from './types.tsx';

type State = InternalState;

type Action =
	| SetVerticalScrollOffsetAction
	| SetVerticalScrollDeltaAction
	| SetHorizontalScrollOffsetAction
	| SetTableSizeAction
	| SetMultiAddAnchorAction
	| ClearMultiAddAnchorAction
	| SetActiveItemAction
	| ClearActiveItemAction
	| SetResizingColumnAction
	| SetColumnSortOrderAction
	| SetSortOrderWithOverrideAction
	| SetSortedRowIdsHashAction
	| SetRowBorderWidthAction
	| SetRowHeightMappingAction
	| BatchUpdateRowHeightMappingAction
	| SetScrollbarSizeAction
	| SetHeaderHeightAction
	| SetRenderSidebarIconAction;

export const initialState: InternalState = {
	verticalScrollOffset: 0,
	verticalScrollDelta: 0,
	horizontalScrollOffset: 0,
	// FSN-4037 Remove draggingRowId related logic during FF cleanup
	draggingRowId: undefined,
	multiAddAnchorId: undefined,
	resizingColumnId: undefined,
	draggingColumnId: undefined,
	isSortOverrideEnabled: false,
	activeSortedColumnConfiguration: undefined,
	activeItem: undefined,
	tableSize: { width: 0, height: 0, widthWithoutScrollbarOffset: 0, rowMargin: 0 },
	rowBorderWidth: 0,
	rowHeightMapping: {},
	scrollbarSize: 0,
	sortedRowIdsHash: {},
	headerHeight: 0,
	renderSidebarIcon: undefined,
	shouldUseCssForRendering: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: InternalState = initialState, action: Action): State => {
	switch (action.type) {
		case SET_VERTICAL_SCROLL_OFFSET:
			return {
				...state,
				verticalScrollOffset: action.payload,
			};
		case SET_VERTICAL_SCROLL_DELTA:
			return {
				...state,
				verticalScrollDelta: action.payload,
			};

		case SET_HORIZONTAL_SCROLL_OFFSET:
			return {
				...state,
				horizontalScrollOffset: action.payload,
			};

		case SET_MULTI_ADD_ANCHOR:
			return {
				...state,
				multiAddAnchorId: action.payload,
			};

		case CLEAR_MULTI_ADD_ANCHOR:
			return {
				...state,
				multiAddAnchorId: undefined,
			};

		case SET_TABLE_SIZE: {
			return {
				...state,
				tableSize: action.payload,
			};
		}

		case SET_RESIZING_COLUMN:
			return {
				...state,
				resizingColumnId: action.payload,
			};

		case SET_COLUMN_SORT_ORDER:
			return {
				...state,
				activeSortedColumnConfiguration: action.payload,
				isSortOverrideEnabled: false,
			};

		case SET_ACTIVE_ITEM:
			return {
				...state,
				activeItem: action.payload,
			};

		case CLEAR_ACTIVE_ITEM:
			return {
				...state,
				activeItem: undefined,
			};

		case SET_SORT_ORDER_WITH_OVERRIDE:
			return {
				...state,
				isSortOverrideEnabled: action.payload.isSortOverrideEnabled,
			};

		case SET_SORTED_ROW_IDS_HASH:
			return {
				...state,
				sortedRowIdsHash: action.payload,
			};
		case SET_ROW_BORDER_WIDTH:
			return {
				...state,
				rowBorderWidth: action.payload,
			};
		case SET_ROW_HEIGHT_MAPPING:
			return {
				...state,
				rowHeightMapping: action.payload,
			};
		case BATCH_UPDATE_ROW_HEIGHT_MAPPING: {
			const { rowHeightMapping, rowIds } = action.payload;
			const validatedRowHeights = pick(state.rowHeightMapping, rowIds);
			return {
				...state,
				rowHeightMapping: {
					...validatedRowHeights,
					...rowHeightMapping,
				},
			};
		}

		case SET_SCROLLBAR_SIZE:
			return {
				...state,
				scrollbarSize: action.payload,
			};
		case SET_HEADER_HEIGHT:
			return {
				...state,
				headerHeight: action.payload,
			};
		case SET_RENDER_SIDE_BAR_ICON:
			return {
				...state,

				renderSidebarIcon: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
