import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '../common/table-redux.tsx';
import { set as setTableSize } from '../ops/table-size/action.tsx';
import { isAutoHeight } from '../state/consumer/selectors/index.tsx';
import { getTableWidth, getShouldUseCssForRendering } from '../state/internal/selectors.tsx';
import { getTableHeight, getVirtualBoundaries } from '../state/selectors/index.tsx';
import type { State } from '../state/types.tsx';
import DetailsPanel from './details/index.tsx';
import Table from './index-dumb.tsx';
import TableMain from './table/index.tsx';

export default connect(
	(state: State) => ({
		isAutoHeight: isAutoHeight(state),
		width: getTableWidth(state),
		height: getTableHeight(state),
		virtualBoundaries: fg('jsm_flash_virtual_table_rows_number_analytics')
			? getVirtualBoundaries(state)
			: undefined,
		TableMain,
		DetailsPanel,
		shouldUseCssForRendering: getShouldUseCssForRendering(state),
	}),
	{ setTableSize },
)(Table);
