import React, { type ReactElement, useMemo } from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { getActiveTrace } from '@atlaskit/react-ufo/experience-trace-id-context';
import { ErrorHashAndTraceId } from '@atlassian/jira-errors-handling/src/ui/error-hash-and-trace-id/ErrorHashAndTraceId.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import ErrorImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error/index.tsx';
import SearchErrorImageComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/search-error/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

export const ProjectNotFound = () => {
	const { formatMessage } = useIntl();
	const projectsPath = '/jira/projects';

	return (
		<>
			<DocumentTitle title={formatMessage(messages.notFoundDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(SearchErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.notFoundTitle)}
				description={formatMessage(messages.notFoundDescription)}
				primaryAction={<Link to={projectsPath}>{formatMessage(messages.notFoundAction)}</Link>}
			/>
		</>
	);
};

export const ServiceHubNotFound = () => {
	const projectsPath = '/jira/projects';
	const { formatMessage } = useIntl();

	return (
		<>
			<DocumentTitle title={formatMessage(messages.serviceHubNotFoundDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(SearchErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.serviceHubNotFoundTitle)}
				description={formatMessage(messages.serviceHubNotFoundDescription)}
				primaryAction={
					<Link to={projectsPath}>{formatMessage(messages.serviceHubNotFoundAction)}</Link>
				}
			/>
		</>
	);
};

export const GenericError = () => {
	const { formatMessage } = useIntl();
	const traceId = useMemo(() => {
		const traceContext = getActiveTrace();

		return traceContext?.traceId;
	}, []);
	let description: ReactElement | string = formatMessage(messages.genericErrorDescription);
	if (traceId) {
		const error = new Error('An error occurred while loading this page');
		description = (
			<>
				<div>{description}</div>
				<Box xcss={styles}>
					<ErrorHashAndTraceId error={error} traceId={traceId} />
				</Box>
			</>
		);
	}
	return (
		<>
			<DocumentTitle title={formatMessage(messages.genericDocumentTitleJsm)} />
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(ErrorImageComponent)}
				maxImageWidth={120}
				header={formatMessage(messages.genericErrorTitle)}
				description={description}
			/>
		</>
	);
};

const styles = xcss({
	marginTop: 'space.100',
});
