import React from 'react';
import IssueView from '@atlassian/jira-servicedesk-issue/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { QueuesIssueViewContentProps } from '../../types.tsx';

const QueuesIssueViewContent = (props: QueuesIssueViewContentProps) => {
	const { issueNavigatorData } = props;

	return (
		<UFOSegment name="queues-issue-view">
			<IssueView issueNavigatorData={issueNavigatorData} />
		</UFOSegment>
	);
};

export default QueuesIssueViewContent;
