import { connect } from '../../../../common/table-redux.tsx';
import { getVisibleCoreColumnsWidth } from '../../../../state/consumer/selectors/columns/index.tsx';
import { showBottomDecal } from '../../../../state/consumer/selectors/index.tsx';
import {
	getTableWidthWithoutScrollbarOffset,
	getShouldUseCssForRendering,
} from '../../../../state/internal/selectors.tsx';
import {
	getTableHeight,
	hasHorizontalScrollbar,
	getOffsetFromBottom,
	isHorizontalScrollLeftShadowVisible,
	isHorizontalScrollRightShadowVisible,
} from '../../../../state/selectors/index.tsx';
import type { State } from '../../../../state/types.tsx';
import HorizontalScrollOverlay from './index-dumb.tsx';

export default connect(
	(state: State) => ({
		getOffsetFromBottom: getOffsetFromBottom(state),
		isHorizontalScrollVisible: hasHorizontalScrollbar(state),
		offset: getVisibleCoreColumnsWidth(state),
		showBottomDecal: showBottomDecal(state),
		showLeftShadow: isHorizontalScrollLeftShadowVisible(state),
		showRightShadow: isHorizontalScrollRightShadowVisible(state),
		tableHeight: getTableHeight(state),
		tableWidth: getTableWidthWithoutScrollbarOffset(state),
		shouldUseCssForRendering: getShouldUseCssForRendering(state),
	}),
	{},
)(HorizontalScrollOverlay);
