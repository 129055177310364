// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType, createRef, useEffect } from 'react';

import { styled } from '@compiled/react';
import type { Size } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';

import { Flex, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { withTheme } from '../app/context/theme-context/index.tsx';
import type { TableSize, VirtualBoundaries } from '../model/index.tsx';
import type { CompiledTheme } from '../model/themes/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isAutoHeight: boolean; hideVerticalScrollBar: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isAutoHeight }) => (isAutoHeight ? 'auto' : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isAutoHeight }) => (isAutoHeight ? undefined : '1 0 auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ hideVerticalScrollBar }) => (hideVerticalScrollBar ? 'hidden' : 'default'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullHeightAutoSizer = styled(AutoSizer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '100% !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AutoSizedContentWrapper = styled.div<{
	width: string | number;
	height: string | number;
}>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
});

const VirtualBoundriesTracker = ({
	visibleStart,
	visibleEnd,
}: {
	visibleStart: number;
	visibleEnd: number;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (visibleStart === 0) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'virtualBoundaries calculated', {
				visibleEnd,
			});
		}
	}, [visibleStart, visibleEnd, createAnalyticsEvent]);

	return null;
};

type Props = {
	width: number;
	height: number;
	isAutoHeight: boolean;
	setTableSize: (size: TableSize) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TableMain: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	DetailsPanel: ComponentType<Record<any, any>>;
	theme: CompiledTheme;
	shouldUseCssForRendering: boolean;
	virtualBoundaries?: VirtualBoundaries;
};

// eslint-disable-next-line jira/react/no-class-components
class Table extends PureComponent<Props> {
	componentDidMount(): void {
		if (fg('css_support_for_virtual_table')) {
			if (this.flexRef.current) {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				const { height, width } = this.flexRef.current.getBoundingClientRect();
				this.onResize({
					height,
					width,
				});
			}
		}
	}

	onResize = (size: Size) => {
		const { setTableSize, theme } = this.props;

		// AutoSizer uses offsetWidth/offsetHeight for dimensions which rounds and can cause scrollbars.
		// i.e. height of 700.5 will be rounded to 701
		// We need to remove a single pixel to prevent this
		// https://github.com/bvaughn/react-virtualized/issues/1287
		const width = size.width - 1;
		const height = size.height - 1;
		setTableSize({
			height,
			width,
			widthWithoutScrollbarOffset: width - theme.scrollbar.offset,
			rowMargin: theme.header.sideMargin * 2,
		});
	};

	flexRef = createRef();

	render() {
		const { width, height, isAutoHeight, shouldUseCssForRendering, TableMain, DetailsPanel } =
			this.props;

		const autoSizerStyle = isAutoHeight ? { height: 'auto' } : {};
		const hideVerticalScrollBar = __SERVER__ && fg('issuelist_ssr_hide_scroll_bars');

		if (shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
			return (
				<Wrapper
					hideVerticalScrollBar={hideVerticalScrollBar}
					isAutoHeight={isAutoHeight}
					data-component-selector="virtual-table.view.table-wrapper"
				>
					<Flex ref={this.flexRef} xcss={[containerStyles]}>
						<TableMain />
						<DetailsPanel />
					</Flex>
					{this.props.virtualBoundaries && fg('jsm_flash_virtual_table_rows_number_analytics') && (
						<VirtualBoundriesTracker
							visibleStart={this.props.virtualBoundaries.visibleStart}
							visibleEnd={this.props.virtualBoundaries.visibleEnd}
						/>
					)}
				</Wrapper>
			);
		}

		return (
			<Wrapper
				isAutoHeight={isAutoHeight}
				hideVerticalScrollBar={__SERVER__ && fg('issuelist_ssr_hide_scroll_bars')}
				data-component-selector="virtual-table.view.table-wrapper"
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<FullHeightAutoSizer onResize={this.onResize} style={{ ...autoSizerStyle }}>
					{() => (
						<AutoSizedContentWrapper width={width} height={height}>
							<TableMain />
							<DetailsPanel />
						</AutoSizedContentWrapper>
					)}
				</FullHeightAutoSizer>
				{this.props.virtualBoundaries && fg('jsm_flash_virtual_table_rows_number_analytics') && (
					<VirtualBoundriesTracker
						visibleStart={this.props.virtualBoundaries.visibleStart}
						visibleEnd={this.props.virtualBoundaries.visibleEnd}
					/>
				)}
			</Wrapper>
		);
	}
}

export default withTheme(Table);

const containerStyles = xcss({
	width: '100%',
	height: '100%',
});
