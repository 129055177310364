import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '../../../../../../common/table-redux.tsx';
import type { ColumnId } from '../../../../../../model/columns/index.tsx';
import { isFirstColumn } from '../../../../../../state/consumer/selectors/columns/index.tsx';
import { getShouldUseCssForRendering } from '../../../../../../state/internal/selectors.tsx';
import { getColumnWidth, getColumn } from '../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../state/types.tsx';
import DefaultColumn from './default-column/index.tsx';
import ColumnWrapper from './index-dumb.tsx';

export default connect(
	(state: State, { id }: { id: ColumnId }) => ({
		width: getColumnWidth(state, id),
		column: fg('css_support_for_virtual_table') ? getColumn(state, id) : undefined,
		isFirstColumn: isFirstColumn(state, id),
		DefaultColumn,
		shouldUseCssForRendering: getShouldUseCssForRendering(state),
	}),
	{},
)(ColumnWrapper);
