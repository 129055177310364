import React, { useEffect, useState, useCallback } from 'react';
import noop from 'lodash/noop';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { IssueBoundary } from '@atlassian/jira-issue-view/src/async.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import IssueApp from './issue-app/index.tsx';

export type Props = {
	issueKey: IssueKey;
	analyticsSource: AnalyticsSource;
	onClose: () => void;
	onChange?: (event: ChangeEvent) => void;
	onIssueDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
};

const PACKAGE_NAME = 'jsmQueueAgentViewSidebar';

export const SidebarIssueApp = ({
	analyticsSource,
	issueKey,
	onClose,
	onIssueDeleteSuccess,
	onChange,
}: Props) => {
	const [currentIssueKey, setCurrentIssueKey] = useState(issueKey);
	// @ts-expect-error - TS7031 - Binding element 'nextIssueKey' implicitly has an 'any' type.
	const onIssueKeyChange = useCallback(({ toIssueKey: nextIssueKey }) => {
		setCurrentIssueKey(nextIssueKey);
	}, []);

	useEffect(() => {
		setCurrentIssueKey(issueKey);
	}, [issueKey]);

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName={PACKAGE_NAME}
			attributes={{
				viewMode: 'SIDEBAR',
			}}
		>
			<Placeholder name="issue-app" fallback={<IssueViewSkeleton />}>
				<IssueBoundary packageName={PACKAGE_NAME}>
					<IssueApp
						viewModeOptions={{
							viewMode: 'SIDEBAR',
						}}
						issueKey={currentIssueKey}
						analyticsSource={analyticsSource}
						shouldShowCloseButton
						onClose={onClose}
						onIssueKeyChange={onIssueKeyChange}
						shouldSetInitialFocus
						shouldShowRootProjectsBreadcrumb
						onIssueDeleteSuccess={onIssueDeleteSuccess}
						onIssueDeleteFailure={noop}
						isSpaEnabled={false}
						isLoadedWithPage={false}
						onChange={onChange}
					/>
				</IssueBoundary>
			</Placeholder>
		</ContextualAnalyticsData>
	);
};
