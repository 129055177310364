import type { TableProps } from '@atlassian/jira-virtual-table/src/app/table/types.tsx';
import { ROW_NAVIGATION } from '@atlassian/jira-virtual-table/src/model/navigation/index.tsx';
import type { CreateCellType } from '../../../common/create-cell/view.tsx';
import {
	AUTO_ROW_HEIGHT,
	DEFAULT_ROW_HEIGHT,
	DRAGGABLE_ROWS,
} from '../../../common/table/constants.tsx';
import type { ConnectProps } from '../../../model/cell/index.tsx';
import transformColumns from './columns/index.tsx';
import transformIssueKeysToRows from './rows/index.tsx';
import transformVerticalScrollCallback from './scroll/index.tsx';

const transformScrollCallback = transformVerticalScrollCallback();

export type PropsTransformer = (props: ConnectProps, createCell: CreateCellType) => TableProps;

export const transformProps = (props: ConnectProps, createCell: CreateCellType): TableProps => {
	const {
		issueKeys,
		columns,
		isContentStale,
		shouldSkipVirtualization,
		shouldHideLoadingIndicator,
		shouldRerenderRowOnHover,
		totalPages,
		FieldDataSelector,
		onActiveItemChanged,
		onActiveRowIdChanged,
		onDisplayedRowsChanged,
		onTableFocusFunctionChanged,
		onTableRefChanged,
		onUpdateHorizontalScrollOffset,
		selectedIssueKeys = [],
		contentKey,
		ssrTableSize,
		disableRowHighlight = false,
		renderSidebarIcon,
		appProps,
		shouldUseCssForRendering,
	} = props;
	const { rowIds, rowsConfiguration } = transformIssueKeysToRows(issueKeys, selectedIssueKeys);

	return {
		isContentStale,
		shouldSkipVirtualization,
		shouldHideLoadingIndicator,
		shouldRerenderRowOnHover,
		rowIds,
		rowsConfiguration,
		columns: transformColumns(FieldDataSelector, columns, createCell),
		navigationMode: ROW_NAVIGATION,
		expandedColumnIds: [],
		columnWidths: {},
		hiddenColumnIds: [],
		defaultRowHeight: DEFAULT_ROW_HEIGHT,
		autoRowHeight: AUTO_ROW_HEIGHT,
		draggableRows: DRAGGABLE_ROWS,
		showDecalAtBottom: totalPages > 1,
		NonTemporaryCellWrapper: undefined,
		onActiveItemChanged,
		onActiveRowIdChanged,
		onDisplayedRowsChanged,
		onVerticalScrollOffsetChanged: transformScrollCallback(props),
		onHorizontalScrollOffsetChanged: onUpdateHorizontalScrollOffset,
		onRowListFocusFunctionChanged: onTableFocusFunctionChanged,
		onReactPortalContainerRefChanged: onTableRefChanged,
		contentKey,
		ssrTableSize,
		disableRowHighlight,
		renderSidebarIcon,
		premiumSLAColumnIds: appProps.premiumSLAColumns,
		shouldUseCssForRendering,
	};
};
