import type { FlagConfiguration } from '@atlassian/jira-flags/src/async'; // ignore-for-ENGHEALTH-17759
import messages from './messages.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	issueKey,
	fieldName,
	reasonMessage,
}: {
	issueKey: string;
	fieldName: string;
	reasonMessage: string | undefined;
}): FlagConfiguration => {
	if (reasonMessage) {
		return {
			type: 'error',
			title: messages.title,
			description: [
				messages.description,
				{
					issueKey,
					fieldName: fieldName.toLowerCase(),
					reasonMessage,
				},
			],
		};
	}
	return {
		type: 'error',
		title: messages.title,
		description: [
			messages.simpleDescription,
			{
				issueKey,
				fieldName: fieldName.toLowerCase(),
			},
		],
	};
};
