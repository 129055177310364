import React, { useEffect, useRef, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import {
	getDetailViewWidthRatio,
	setDetailViewWidthRatio,
	MIN_DETAILS_WIDTH_ISSUE,
	WINDOW_RESIZE_THROTTLE,
} from './utils.tsx';

type Props = {
	parentWidth: number;
	children: ReactNode;
	onResize: ((arg1: number) => void) | undefined;
	setIsResizing: (arg1: boolean) => void;
};

const IS_DRAGGING_CLASS_NAME = 'ak-dnd-is-dragging';

const Resizer = ({ parentWidth, children, onResize, setIsResizing }: Props) => {
	const [width, setWidth] = useState<number>(0);
	const startX = useRef(0);
	const extraWidth = useRef(0);

	const onWindowResize = throttle(() => {
		const newWidth = Math.max(parentWidth * getDetailViewWidthRatio(), MIN_DETAILS_WIDTH_ISSUE);
		if (newWidth !== width) {
			setWidth(newWidth);
		}
	}, WINDOW_RESIZE_THROTTLE);

	useEffect(() => {
		setWidth(Math.max(parentWidth * getDetailViewWidthRatio(), MIN_DETAILS_WIDTH_ISSUE));
	}, [parentWidth]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('resize', onWindowResize);
		onResize?.(width);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('resize', onWindowResize);
		};
	}, [onResize, onWindowResize, width]);

	const onMouseDown = useEvent((e: React.MouseEvent) => {
		startX.current = e.clientX + (width - MIN_DETAILS_WIDTH_ISSUE);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mousemove', onMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mouseup', onMouseUp);
		setIsResizing(true);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.body) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.classList.add(IS_DRAGGING_CLASS_NAME);
		}
	});

	const onMouseMove = useEvent((e: MouseEvent) => {
		extraWidth.current = Math.max(startX.current - e.clientX, 0);
		const newWidth = Math.min(MIN_DETAILS_WIDTH_ISSUE + extraWidth.current, parentWidth / 2);
		if (newWidth !== width) {
			setWidth(newWidth);
		}
	});

	const onMouseUp = useEvent(() => {
		startX.current = 0;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mousemove', onMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mouseup', onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.body) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.classList.remove(IS_DRAGGING_CLASS_NAME);
		}
		const widthRatio = width === MIN_DETAILS_WIDTH_ISSUE ? 0 : width / parentWidth;
		setDetailViewWidthRatio(widthRatio);
		setIsResizing(false);
	});

	return (
		<Container minWidth={width}>
			<ResizeHandle onMouseDown={onMouseDown} role="presentation" />
			{children}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ minWidth: string | number }>({
	width: '400px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: (props) => `${props.minWidth || 400}px`,
	minHeight: '100%',
	maxHeight: '100%',
	overflowY: 'auto',
	overflowX: 'hidden',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.card,
	marginLeft: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandle = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize}px`,
	cursor: 'ew-resize',
	position: 'relative',
	alignSelf: 'stretch',
	'&::after': {
		content: '',
		width: token('space.025', '2px'),
		height: token('space.250', '20px'),
		top: '50%',
		transform: 'translateY(-50%)',
		left: token('space.050', '4px'),
		transition: 'background 0.2s',
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border', colors.N30),
	},
	'&:hover::after, &:active::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.brand.bold', colors.B200),
		height: '100%',
	},
});

export default Resizer;
