import React from 'react';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props/src/index.tsx';
import { DocumentTitle as DocumentTitleDI } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const PageTitle = (props: Props) => {
	const { DocumentTitle, section, projectName, subsection } = props;

	const { formatMessage } = useIntl();

	const title = subsection
		? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ proformaForms: MessageDescriptorV2; chat: MessageDescriptorV2; incidentManagementSettings: MessageDescriptorV2; ... 32 more ...; serviceProject: MessageDescriptorV2; }'.
			`${projectName} - ${formatMessage(messages[section])} - ${subsection} - ${formatMessage(messages.serviceProject)}`
		: // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ proformaForms: MessageDescriptorV2; chat: MessageDescriptorV2; incidentManagementSettings: MessageDescriptorV2; ... 32 more ...; serviceProject: MessageDescriptorV2; }'.
			`${projectName} - ${formatMessage(messages[section])} - ${formatMessage(messages.serviceProject)}`;

	return <DocumentTitle title={title} />;
};

export const ServiceDeskDocumentTitle = withInjectedProps(PageTitle, {
	DocumentTitle: DocumentTitleDI,
});

export default ServiceDeskDocumentTitle;
