import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { levelIndentBaseline } from '../../../constants/index.tsx';
import type { CompiledTheme } from '../../../model/themes/index.tsx';

type Props = {
	width: number;
	isFirstColumn: boolean;
};

/**
 * Shared style for column width. Both the parent and child require this
 * calculation; the parent, in case an overriden column is rendered.  The
 * child, to apply the drag animation.
 */
export const getWidthStyleMixin = ({ width, isFirstColumn }: Props) => `
    width: ${isFirstColumn ? width + levelIndentBaseline : width}px;
`;

export const getWidth = ({ width, isFirstColumn }: Props) =>
	`${isFirstColumn ? width + levelIndentBaseline : width}px`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RowHighlight = styled.div<{
	isRowLeftEdgeVisible: boolean;
	isRowRightEdgeVisible: boolean;
	theme: CompiledTheme;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ isRowRightEdgeVisible }) => (isRowRightEdgeVisible ? 'visible' : 'hidden'),
	position: 'absolute',
	top: token('space.negative.100', '-8px'),
	right: 0,
	bottom: token('space.negative.100', '-8px'),
	left: 0,
	pointerEvents: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'&::after': ({ isRowRightEdgeVisible, theme }) => ({
		/* Hides the right edge shadow */
		position: 'absolute',
		top: 0,

		right: isRowRightEdgeVisible ? 0 : token('space.negative.100', '-8px'),
		bottom: 0,
		left: 0,
		/*
            Fills in border that is suppose to come from the box-shadow
            but is cut off by the table
        */
		borderWidth: '1px',
		borderStyle: 'solid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border', colors.N30),
		borderRadius: theme.row.borderRadius,
		content: '',
		pointerEvents: 'none',
		zIndex: 1,
		/* Pushes the box-shadow to the row's actual top and bottom boundary */
		margin: `${token('space.100', '8px')} ${token('space.0', '0px')}`,
	}),
});
