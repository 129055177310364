import React, { type ComponentType, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { PaginationPropTypes } from '@atlaskit/pagination';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyPagination = lazyForPaint<ComponentType<PaginationPropTypes<ReactNode>>>(
	() => import(/* webpackChunkName: "async-issue-table-pagination" */ '@atlaskit/pagination'),
);

const AsyncPagination: ComponentType<PaginationPropTypes<ReactNode>> = (
	props: PaginationPropTypes<ReactNode>,
) => (
	<JSErrorBoundary id="async-issue-table-pagination" packageName="issueTable" fallback="unmount">
		<Placeholder name="async-issue-table-pagination" fallback={<LoadingComponent />}>
			<LazyPagination {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export { AsyncPagination as default };

// Must be the height of the Atlaskit Pagination component
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingComponent = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	width: '10px',
});
