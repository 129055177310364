// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import withInjectedProps from '@atlassian/jira-common-util-with-injected-props/src/index.tsx';
import { SubProductUpdater as SubProductUpdaterDI } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck as AuthenticationCheckDI } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { isHelpCenterSettingsPath } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/is-help-center-settings-path/index.tsx';
import AccessCheckDI from '../access-check/index.tsx';
import {
	ProjectNotFound as ProjectNotFoundDI,
	GenericError as GenericErrorDI,
	ServiceHubNotFound as ServiceHubNotFoundDI,
} from '../errors/index.tsx';
import type { Props } from './types.tsx';

/**
 * @deprecated use ServiceDeskAppBase instead
 */
export const SpaPageWrapperView = (props: Props) => {
	const {
		projectContext,
		isLoading,
		error,
		children,
		AccessCheck,
		AuthenticationCheck,
		ProjectNotFound,
		ServiceHubNotFound,
		GenericError,
		SubProductUpdater,
	} = props;

	if (isLoading) return <Spinner />;

	if (error) {
		log.safeErrorWithoutCustomerData('jsdspa-page-wrapper', 'rendering-error-page', error);

		const isHelpCenterSettingsRoute = isHelpCenterSettingsPath();
		if (isHelpCenterSettingsRoute) {
			return (
				<AuthenticationCheck>
					{/* @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'RouteResourceError'. */}
					{error.statusCode === 404 ? <ServiceHubNotFound /> : <GenericError />}
				</AuthenticationCheck>
			);
		}

		return (
			<AuthenticationCheck>
				{/* @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'RouteResourceError'. */}
				{error.statusCode === 404 ? <ProjectNotFound /> : <GenericError />}
			</AuthenticationCheck>
		);
	}

	if (projectContext) {
		return (
			<>
				<SubProductUpdater subProduct="serviceDesk" />
				<AuthenticationCheck>
					<AccessCheck>{children}</AccessCheck>
				</AuthenticationCheck>
			</>
		);
	}

	return null;
};

export const SpaPageWrapper = withInjectedProps(SpaPageWrapperView, {
	AccessCheck: AccessCheckDI,
	GenericError: GenericErrorDI,
	ProjectNotFound: ProjectNotFoundDI,
	ServiceHubNotFound: ServiceHubNotFoundDI,
	AuthenticationCheck: AuthenticationCheckDI,
	SubProductUpdater: SubProductUpdaterDI,
});

export default SpaPageWrapper;
