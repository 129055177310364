import React from 'react';
import CodeBlock from '@atlaskit/code/block';
import { Box, xcss } from '@atlaskit/primitives';
import { CopyAction, ActionContainer } from './code-block-actions/CopyAction.tsx';

type Props = {
	/**
	 * The source code for the code block
	 */
	source: string;
	/**
	 * The action subject ID to use for the UI analytics event fired when the copy button is clicked
	 */
	copyCodeActionSubjectId?: string;
};

/**
 * A code block with a copy action button
 */
export const CodeBlockWithCopyButton = ({ source, copyCodeActionSubjectId }: Props) => (
	<Box xcss={wrapperStyles} role="region">
		<ActionContainer>
			<CopyAction source={source} actionSubjectId={copyCodeActionSubjectId} />
		</ActionContainer>
		<Box>
			<CodeBlock text={source} showLineNumbers={false} shouldWrapLongLines={false} />
		</Box>
	</Box>
);

const wrapperStyles = xcss({
	width: '100%',
	position: 'relative',
});
