import type { Observable } from 'rxjs/Observable';
import {
	fetchJson$,
	fetchText$,
} from '@atlassian/jira-servicedesk-common/src/utils/fetch/index.tsx';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { SORT_PREFIX } from '../../services/sort/constants.tsx';
import type { ManualSorting } from '../../state/reducers/persisted/queue/types.tsx';

export const getKeyFromQueueId = (queueId: QueueId) => `${SORT_PREFIX}${queueId}`;

export const getUserPropertiesUrl = (accountId: AccountId, propertyKey: string): string =>
	`/rest/api/latest/user/properties/${propertyKey}?accountId=${encodeURIComponent(accountId)}`;

const MISSING_ACCOUNT_ID_ERROR_MSG = 'accountId is missing.';

export const removeQueueIssuesSortingDataUserProperty = (
	queueId: QueueId,
	accountId?: AccountId | null,
): Observable<void> => {
	if (!accountId) {
		throw new Error(MISSING_ACCOUNT_ID_ERROR_MSG);
	}
	return fetchJson$(getUserPropertiesUrl(accountId, getKeyFromQueueId(queueId)), {
		method: 'DELETE',
	});
};

export const updateQueueIssuesSortingDataUserProperty = (
	data: ManualSorting,
	queueId: QueueId,
	accountId?: AccountId | null,
): Observable<void> => {
	if (!accountId) {
		throw new Error(MISSING_ACCOUNT_ID_ERROR_MSG);
	}
	return fetchText$(getUserPropertiesUrl(accountId, getKeyFromQueueId(queueId)), {
		method: 'PUT',
		body: JSON.stringify(data),
	});
};
