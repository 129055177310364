import React, { useCallback, useMemo, type KeyboardEvent, type MouseEvent } from 'react';
import {
	EnvironmentLozenge,
	nameWithCustomEnvironment,
} from '@atlassian/jira-forge-ui-extension-title/src/ui/environment-lozenge/index.tsx';
import type { MenuItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/utils/get-analytics-attributes/types.tsx';
import ExpandableMenuItemTrigger from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/expandable-menu-item-trigger-with-analytics/index.tsx';
import { ExpandableMenuItem, ExpandableMenuItemContent } from '@atlassian/navigation-system';
import { useRouter } from '@atlassian/react-resource-router';
import type { ForgeItemProps } from '../../types.tsx';
import { ForgeIcon } from './forge-icon/index.tsx';
import { ForgeItemExpandable } from './forge-item-expandable/index.tsx';
import { ForgeItemSimple } from './forge-item-simple/index.tsx';

export const ForgeItem = (props: ForgeItemProps) => {
	const [{ location }] = useRouter();
	const {
		id: itemId,
		url,
		showIcon,
		iconUrl,
		environmentType,
		environmentKey,
		name,
		sections,
		overrides,
		analytics,
	} = props;

	const shouldExpandAsDefault = useMemo(() => location.pathname.includes(url), [url, location]);

	const onNestedMenuItemClick = useCallback(
		(event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => {
			if (overrides?.ParentMenuItem?.onClick) {
				overrides.ParentMenuItem.onClick(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					event as MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
				);
			}
			return () => null;
		},
		[overrides],
	);

	const itemAnalytics = useMemo<MenuItemAnalytics>(() => {
		return {
			...analytics,
			itemType: 'app',
			componentType: 'customItem',
			itemId,
		};
	}, [analytics, itemId]);

	const expandableItemAnalytics = useMemo<MenuItemAnalytics>(() => {
		return {
			...itemAnalytics,
			level: 2,
			itemType: 'appSubpage',
			parentItemType: 'app',
		};
	}, [itemAnalytics]);

	if (!sections?.length) {
		return <ForgeItemSimple {...props} analytics={itemAnalytics} />;
	}

	if (overrides?.showSelectedAppMenuOnly) {
		return <ForgeItemExpandable {...props} analytics={itemAnalytics} />;
	}

	return (
		<ExpandableMenuItem isDefaultExpanded={shouldExpandAsDefault}>
			<ExpandableMenuItemTrigger
				elemBefore={showIcon ? <ForgeIcon url={iconUrl} /> : undefined}
				elemAfter={<EnvironmentLozenge environmentType={environmentType} />}
				testId={`navigation-sidebar.project-menu.forge-app.${itemId}.expandable-menu-item-trigger.root`}
				analytics={itemAnalytics}
				onClick={onNestedMenuItemClick}
			>
				{nameWithCustomEnvironment(name, environmentType, environmentKey)}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<ForgeItemExpandable {...props} analytics={expandableItemAnalytics} />
			</ExpandableMenuItemContent>
		</ExpandableMenuItem>
	);
};
