import React, { type ReactNode } from 'react';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { isHelpCenterSettingsPath } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/is-help-center-settings-path/index.tsx';

import { PROJECT_PATH } from './constants.tsx';
import ProductAccessCheck from './product-access-check/index.tsx';
import SettingsAccessCheck from './settings-access-check/index.tsx';

type Props = {
	children: ReactNode;
};

const AccessCheck = ({ children }: Props) => {
	const isSettingsRoute = useCurrentRoute().path.includes(`${PROJECT_PATH}/:projectKey/settings/`);
	const isHelpCenterSettingsRoute = isHelpCenterSettingsPath();

	const Gate =
		isSettingsRoute || isHelpCenterSettingsRoute ? SettingsAccessCheck : ProductAccessCheck;

	return <Gate>{children}</Gate>;
};

export default AccessCheck;
