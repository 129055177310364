import type { MiddlewareAPI } from 'redux';
import type { BatchAction } from 'redux-batched-actions';
import { type ActionsObservable, combineEpics, type Epic } from 'redux-observable';
import type { Observable } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import log from '@atlassian/jira-servicedesk-queues-common/src/log/index.tsx';
import type { QueuesPush } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/queues-push-provider/index.tsx';
import type { Action } from '../state/actions/types.tsx';
import type { State } from '../state/reducers/types.tsx';
import { withUnpackedBatchActions } from './common/batch/index.tsx';
import filterEpic from './filter/index.tsx';
import issueEpic from './issue/index.tsx';
import outdatedDataEpic from './outdated-data/index.tsx';
import pageEpic from './page/index.tsx';
import pollEpic from './poll/index.tsx';
import queueEpic from './queue/index.tsx';
import recentEpic from './recent/add/index.tsx';
import scrollEpic from './scroll/index.tsx';
import sortEpic, { sortEpicNew } from './sort/index.tsx';
import tableEpic from './table/change/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (push: QueuesPush) => {
	const epic: Epic<Action | BatchAction, State> = combineEpics(
		queueEpic(push),
		issueEpic(push),
		pageEpic,
		pollEpic(),
		fg('migrate_sorting_to_user_property') ? sortEpicNew : sortEpic,
		// @ts-expect-error - No overload matches this call.
		outdatedDataEpic,
		recentEpic,
		scrollEpic,
		tableEpic,
		filterEpic,
	);
	return (
		action$: ActionsObservable<Action | BatchAction>,
		store: MiddlewareAPI<State>,
	): Observable<Action | BatchAction> =>
		// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type. | TS7006 - Parameter 'stream' implicitly has an 'any' type.
		epic(withUnpackedBatchActions(action$), store).catch((error, stream) => {
			fireErrorAnalytics({
				meta: {
					id: 'agentViewOps',
					packageName: 'jiraServicedeskQueuesAgentView',
					teamName: 'jsd-shield',
				},
				error: new Error(error),
			});
			log.safeErrorWithoutCustomerData(
				'servicedesk.queues.agent-view.ops',
				`Unhandled error: ${error}`,
			);
			return stream;
		});
};
