// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import type { Store } from 'redux';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import { Provider } from '../../common/table-redux.tsx';
import { CELL_NAVIGATION } from '../../model/navigation/index.tsx';
import { setStateFromProps } from '../../ops/hydrate/set/action.tsx';
import { updateStateFromProps } from '../../ops/hydrate/update/action.tsx';
import { setVertical as setVerticalScrollOffset } from '../../ops/scrolling/offset/action.tsx';
import { scrollToRow } from '../../ops/scrolling/to-row/action.tsx';
import { setRenderSidebarIcon } from '../../state/internal/actions.tsx';
import { initialState as initialInternalState } from '../../state/internal/reducer.tsx';
import type { State } from '../../state/types.tsx';
import View from '../../view/index.tsx';
import { ThemeProvider } from '../context/theme-context/index.tsx';
import theme from './theme.tsx';
import type { TableProps as Props } from './types.tsx';
import createStore from './utils/create-store.tsx';
import transformPropsToState from './utils/transform-props-to-state.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class TableApp extends PureComponent<Props> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps: Partial<Props> = {
		NonTemporaryCellWrapper: undefined,
		navigationMode: CELL_NAVIGATION,

		rowIds: [],
		rowsConfiguration: {},
		defaultRowHeight: Math.max(theme.row.height, theme.row.minHeight),
		autoRowHeight: false,
		draggableRows: false,

		columns: [],
		expandedColumnIds: [],
		columnWidths: {},
		hiddenColumnIds: [],

		onActiveItemChanged: noop,
		onRowListFocusFunctionChanged: undefined,

		contentKey: undefined,
	};

	static getInitialStoreState(props: Props) {
		if (props.shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
			return {
				internal: {
					...initialInternalState,
					shouldUseCssForRendering: true,
					tableSize: {
						width: 0,
						// in case of SSR arbitrary number to allow some rows to be rendered, ideally bigger than any screen you can think of
						// otherwise we will read it from the ref of the wrapper
						height: __SERVER__ ? 10000 : 0,
						// if we don't set it, some editable fields inside cells (e.g. assignee) don't render, and cause errors
						widthWithoutScrollbarOffset: 0,
						rowMargin: 0,
					},
				},
			};
		}

		return __SERVER__ && props.ssrTableSize
			? {
					internal: {
						...initialInternalState,
						tableSize: props.ssrTableSize,
					},
				}
			: undefined;
	}

	constructor(props: Props) {
		super(props);
		const initialStoreState = TableApp.getInitialStoreState(props);
		this.store = createStore(initialStoreState);

		const consumerState = transformPropsToState(props);
		this.store.dispatch(setStateFromProps(consumerState, theme));

		if (props.renderSidebarIcon) {
			this.store.dispatch(setRenderSidebarIcon(props.renderSidebarIcon));
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (!isEqual(this.props, nextProps)) {
			const consumerState = transformPropsToState(nextProps);
			this.store.dispatch(updateStateFromProps(consumerState));

			if (nextProps.renderSidebarIcon) {
				this.store.dispatch(setRenderSidebarIcon(nextProps.renderSidebarIcon));
			}
		}
	}

	scrollToRow(rowId: string) {
		this.store.dispatch(scrollToRow(rowId));
	}

	resetVerticalScrollOffset() {
		this.store.dispatch(setVerticalScrollOffset(0));
	}

	store: Store<State>;

	render() {
		const { defaultRowHeight, autoRowHeight, customScrollBarOffset } = this.props;
		return (
			<Provider store={this.store}>
				<ThemeProvider
					defaultRowHeight={defaultRowHeight}
					autoRowHeight={autoRowHeight}
					customScrollBarOffset={customScrollBarOffset}
				>
					<View />
				</ThemeProvider>
			</Provider>
		);
	}
}
