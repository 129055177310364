import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import log from '@atlassian/jira-servicedesk-queues-common/src/log/index.tsx';
import { removeQueueIssuesSortingDataUserProperty } from '../../../rest/sort/index.tsx';
import { RESET_SORT_ORDER } from '../../../state/actions/table/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import type { State } from '../../../state/reducers/types.tsx';
import { getCreateAnalyticsEvent } from '../../../state/selectors/analytic/index.tsx';
import { getQueueId } from '../../../state/selectors/queue/index.tsx';
import { getAccountId } from '../../../state/selectors/user/index.tsx';

const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(RESET_SORT_ORDER).switchMap(() => {
		const state = store.getState();
		const accountId = getAccountId(state);
		const queueId = getQueueId(state);
		const createAnalyticsEvent = getCreateAnalyticsEvent(state);

		return removeQueueIssuesSortingDataUserProperty(queueId, accountId)
			.switchMap(() => {
				if (createAnalyticsEvent) {
					fireOperationalAnalytics(
						createAnalyticsEvent({}),
						'viewQueueManualSortingToUserProperty reset',
					);
				}
				return Observable.empty<never>();
			})
			.catch((error) => {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'opsRemoveQueueSorting',
						packageName: 'jira-servicedesk-queues-agent-view',
						teamName: 'Flash',
					},
				});

				log.safeErrorWithoutCustomerData(
					'servicedesk.queues.agent-view.ops',
					'User properties failure for reset sorting',
				);

				return Observable.empty<never>();
			});
	});

export default epic;
