import { combineEpics, type Epic } from 'redux-observable';
import type { Action } from '../../state/actions/types.tsx';
import type { State } from '../../state/reducers/types.tsx';
import changedEpic from './changed/index.tsx';
import removeSortingEpic from './reset-user-property/index.tsx';
import resetEpic from './reset/index.tsx';
import updateSortingEpic from './update-user-property/index.tsx';

export const sortEpicNew: Epic<Action, State> = combineEpics<Action, State>(
	changedEpic,
	resetEpic,
	removeSortingEpic,
	updateSortingEpic,
);
// cleanup migrate_sorting_to_user_property
export default combineEpics<Action, State>(changedEpic, resetEpic);
