import React, { Component, type ComponentType, type ReactNode } from 'react';
import { colors } from '@atlaskit/theme';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { withTheme } from '../../../../../../../app/context/theme-context/index.tsx';
import type { Cell } from '../../../../../../../model/cells/index.tsx';
import type { ColumnId } from '../../../../../../../model/columns/index.tsx';
import type { RowId, RowContentUpdateCallback } from '../../../../../../../model/rows/index.tsx';
import type { CompiledTheme } from '../../../../../../../model/themes/index.tsx';

const getWrapperStyles = (backgroundColor: string, theme: CompiledTheme) => {
	const style = {
		display: 'flex',
		boxSizing: 'border-box',
		borderTop: `${theme.row.borderWidth}px solid ${theme.row.borderColor}`,
		borderBottom: `${theme.row.borderWidth}px solid ${theme.row.borderColor}`,
		height: theme.row.height,
		minHeight: theme.row.minHeight,
		marginLeft: '0px',
		boxShadow: 'none',
	};

	if (theme.row.useCssHover) {
		return style;
	}

	return { ...style, backgroundColor };
};

type Props = {
	id: RowId;
	isActive: boolean;
	coreColumnIds: ColumnId[];
	additionalColumnIds: ColumnId[];
	shouldRerenderRowOnHover?: boolean;
	AdditionalColumnsContainer: ComponentType<{
		children: ReactNode;
	}>;
	CellWrapper: ComponentType<
		Cell & {
			isRowHovered: boolean;
			isRowKeyboardActive: boolean;
			onCellMount: (arg1: RowId, arg2: number) => void;
		}
	>;
	RowChildOperationIndicator: ComponentType<{
		rowId: RowId;
		isRowHovered: boolean;
		isRowKeyboardActive: boolean;
	}>;
	theme: CompiledTheme;
	onRowContentMount: (arg1: number, arg2: number, arg3: ColumnId[], arg4: number) => void;
	onRowContentUpdate: RowContentUpdateCallback;
	ConditionalErrorBoundary: ComponentType<{
		children: ReactNode;
		columnId: ColumnId;
	}>;
};

type State = {
	isHovered: boolean;
	render: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
class RowContent extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isHovered: false,
			render: !props.coreColumnIds.length,
		};
		this.beginTime = Date.now();
	}

	componentDidMount() {
		const { onRowContentMount, coreColumnIds, additionalColumnIds } = this.props;
		if (!this.state.render) {
			this.timeoutId = setTimeout(() => this.setState({ render: true }), 16);
		}
		this.recalculateHeight();
		// @ts-expect-error - TS2769 - No overload matches this call.
		const allColumns = [].concat(coreColumnIds, additionalColumnIds);
		onRowContentMount &&
			onRowContentMount(this.beginTime, Date.now(), allColumns, allColumns.length);
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		return (
			this.state.render !== nextState.render ||
			this.state.isHovered !== nextState.isHovered ||
			this.props.id !== nextProps.id ||
			this.props.isActive !== nextProps.isActive ||
			this.props.coreColumnIds !== nextProps.coreColumnIds ||
			this.props.additionalColumnIds !== nextProps.additionalColumnIds
		);
	}

	componentWillUnmount() {
		clearTimeout(this.timeoutId);
	}

	onMouseEnter = () => {
		if (!this.props.theme.row.useCssHover || (this.props.shouldRerenderRowOnHover ?? false)) {
			this.setState({ isHovered: true });
		}
	};

	onMouseLeave = () => {
		if (!this.props.theme.row.useCssHover || (this.props.shouldRerenderRowOnHover ?? false)) {
			this.setState({ isHovered: false });
		}
	};

	setRef = (el: HTMLElement | null) => {
		this.ref = el;
	};

	determineRowColor(isActive: boolean, isHovered: boolean): string {
		if (isHovered) {
			return this.props.theme.row.hoveredColor;
		}
		if (isActive) {
			return this.props.theme.row.activeColor;
		}

		return colors.N0;
	}

	// @ts-expect-error - TS2564 - Property 'ref' has no initializer and is not definitely assigned in the constructor.
	ref: HTMLElement | null;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	timeoutId: any;

	beginTime: number;

	recalculateHeight = () => {
		const { id, onRowContentUpdate } = this.props;
		if (this.ref && onRowContentUpdate) {
			onRowContentUpdate(id, this.ref.getBoundingClientRect().height);
		}
	};

	renderChildOperationIndicator() {
		const { id, RowChildOperationIndicator, isActive, ConditionalErrorBoundary } = this.props;
		const { isHovered } = this.state;

		return (
			<ConditionalErrorBoundary columnId="child-operation-indicator">
				<RowChildOperationIndicator
					rowId={id}
					isRowHovered={isHovered}
					isRowKeyboardActive={isActive}
				/>
			</ConditionalErrorBoundary>
		);
	}

	renderCoreColumns() {
		const { id, coreColumnIds, CellWrapper, isActive, ConditionalErrorBoundary } = this.props;
		const { isHovered } = this.state;

		return coreColumnIds.map((columnId) => (
			<ConditionalErrorBoundary key={`${columnId}-${id}`} columnId={columnId}>
				<CellWrapper
					key={columnId}
					rowId={id}
					columnId={columnId}
					isRowHovered={isHovered}
					isRowKeyboardActive={isActive}
					onCellMount={this.recalculateHeight}
				/>
			</ConditionalErrorBoundary>
		));
	}

	renderAdditionalColumns() {
		const {
			id,
			additionalColumnIds,
			AdditionalColumnsContainer,
			CellWrapper,
			isActive,
			ConditionalErrorBoundary,
		} = this.props;
		const { isHovered } = this.state;

		return additionalColumnIds.length > 0 ? (
			<AdditionalColumnsContainer>
				{additionalColumnIds.map((columnId) => (
					<ConditionalErrorBoundary key={`${columnId}-${id}`} columnId={columnId}>
						<CellWrapper
							key={columnId}
							rowId={id}
							columnId={columnId}
							isRowHovered={isHovered}
							isRowKeyboardActive={isActive}
							onCellMount={this.recalculateHeight}
						/>
					</ConditionalErrorBoundary>
				))}
			</AdditionalColumnsContainer>
		) : null;
	}

	render() {
		const { isActive, theme } = this.props;
		const { isHovered } = this.state;

		const color = this.determineRowColor(isActive, isHovered);

		return this.state.render ? (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="virtual-table-row"
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				onFocus={undefined}
			>
				<div
					ref={this.setRef}
					// @ts-expect-error - TS2322 - Type '{ display: string; boxSizing: string; borderTop: string; borderBottom: string; height: string; minHeight: string; marginLeft: string; boxShadow: string; } | { backgroundColor: string; display: string; ... 6 more ...; boxShadow: string; }' is not assignable to type 'CSSProperties | undefined'.
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={getWrapperStyles(color, theme)}
				>
					{this.renderChildOperationIndicator()}
					{this.renderCoreColumns()}
					{this.renderAdditionalColumns()}
				</div>
			</div>
		) : (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="virtual-table-row"
				// @ts-expect-error - TS2322 - Type '{ display: string; boxSizing: string; borderTop: string; borderBottom: string; height: string; minHeight: string; marginLeft: string; boxShadow: string; } | { backgroundColor: string; display: string; ... 6 more ...; boxShadow: string; }' is not assignable to type 'CSSProperties | undefined'.
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={getWrapperStyles(color, theme)}
			>
				{this.renderChildOperationIndicator()}
				{this.renderCoreColumns()}
			</div>
		);
	}
}

export default ComponentWithAnalytics('virtualTableRows', {
	onRowContentMount: 'mounted',
})(withTheme(RowContent));
