import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import type SteppableScheduler from '@atlassian/jira-common-util-test/src/steppable-interval.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ErrorResponse } from '@atlassian/jira-servicedesk-common/src/utils/fetch/index.tsx';
import { LOAD_ISSUES_ACTION_SOURCE_POLL } from '../../model/index.tsx';
import poll from '../../services/poll/index.tsx';
import { loadIssuesAction } from '../../state/actions/issue/index.tsx';
import { SHUTDOWN } from '../../state/actions/lifecycle/index.tsx';
import type { Action } from '../../state/actions/types.tsx';
import {
	STOP_POLL,
	RESTART_POLL,
	pollSuccessAction,
	pollContentNotModifiedAction,
	pollFailureAction,
} from '../../state/actions/update-metadata/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { getIsCurrentDataFiltered } from '../../state/selectors/filter/index.tsx';
import { getFetchStartIndex } from '../../state/selectors/queue/index.tsx';

const INITIAL_POLLING_INTERVAL = 5000;
export const MAXIMUM_POLLING_INTERVAL = 1 * 60 * 1000;
export const MAXIMUM_POLLING_INTERVAL_WHEN_ERROR = 5 * 60 * 1000;
// If the queue is on the currently active browser tab, delay the polling
// OR as initial delay right after tab gets visible
export const FAST_POLLING_INTERVAL = 2 * 1000;

const BROWSER_TAB_VISIBLE_STATE = 'visible';
const getInterval = (initialInterval: number): number => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (document.visibilityState !== BROWSER_TAB_VISIBLE_STATE) {
		return FAST_POLLING_INTERVAL;
	}
	return initialInterval;
};

const getNextInterval = (interval: number, statusCode: number): number => {
	if (statusCode >= 500 && fg('jsm_queue_polling_exponential_backoff')) {
		const nextInterval = interval * 5;
		return Math.min(MAXIMUM_POLLING_INTERVAL_WHEN_ERROR, nextInterval);
	}
	const nextInterval = interval * 1.1;
	return Math.min(MAXIMUM_POLLING_INTERVAL, nextInterval);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
		initialInterval: number = INITIAL_POLLING_INTERVAL,
		scheduler?: SteppableScheduler,
	) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) => {
		let isShutdown = false;
		return action$.ofType(SHUTDOWN, STOP_POLL, RESTART_POLL).switchMap((action) => {
			if (action.type === SHUTDOWN || isShutdown) {
				isShutdown = true;
				return Observable.empty<never>();
			}

			if (action.type === STOP_POLL) {
				return Observable.empty<never>();
			}

			const pollingInterval$ = new BehaviorSubject(getInterval(initialInterval));
			return pollingInterval$
				.switchMap((interval) => {
					let initialDelay = interval;
					if (action.payload?.skipInitialDelay) {
						initialDelay = 0;
					}
					return Observable.timer(initialDelay, interval, scheduler).switchMap(() =>
						Observable.of(interval),
					);
				})
				.switchMap((interval) => {
					const state = store.getState();
					// If browser tab is not visible, do nothing.

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					if (document.visibilityState !== BROWSER_TAB_VISIBLE_STATE) {
						pollingInterval$.next(getInterval(initialInterval));
						return Observable.empty<never>();
					}

					return (
						poll(state)
							.mergeMap((result) => {
								// going back to the original interval if there is new data
								pollingInterval$.next(getInterval(initialInterval));

								if (getIsCurrentDataFiltered(state)) {
									return Observable.of(pollSuccessAction(result.projectStateHash));
								}

								return Observable.of(
									pollSuccessAction(result.projectStateHash),
									// @ts-expect-error - TS2769 - No overload matches this call.
									loadIssuesAction(getFetchStartIndex(state), LOAD_ISSUES_ACTION_SOURCE_POLL),
								);
							})
							// 304 returned by endpoint
							// @ts-expect-error - TS2345 - Argument of type '(error: ErrorResponse) => Observable<PollContentNotModifiedAction> | Observable<PollFailureAction>' is not assignable to parameter of type '(err: any, caught: Observable<PollSuccessAction>) => ObservableInput<PollContentNotModifiedAction>'.
							.catch((error: ErrorResponse) => {
								// going back to the original interval if there is new data
								pollingInterval$.next(getNextInterval(interval, error.statusCode));
								if (error.statusCode === 304) {
									return Observable.of(pollContentNotModifiedAction());
								}
								return Observable.of(pollFailureAction());
							})
					);
				});
		});
	};
