import type { Reducer } from 'redux';
import { assoc, chain, merge, updateIn } from 'icepick';
import { getMark, setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import type { Issue } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import {
	CHANGE_ISSUE_FIELD_VALUE,
	type ChangeIssueFieldValueAction,
} from '@atlassian/jira-servicedesk-queues-common/src/state/actions/issue/field/index.tsx';
import type { CollectionItem } from '../../../../model/index.tsx';
import { type FilterBadQueryAction, IS_FILTER_FAILED } from '../../../actions/filter/index.tsx';
import {
	LOAD_ISSUES_SUCCESS,
	type LoadIssuesSuccessAction,
} from '../../../actions/issue/index.tsx';
import { RESET_STATE, type ResetStateAction } from '../../../actions/page/index.tsx';
import {
	PAGE_CHANGED,
	SORT_ORDER_CHANGED,
	type PageChangedAction,
	type SortOrderChangedAction,
} from '../../../actions/table/index.tsx';
import type { Issues } from './types.tsx';
import { addIssuesResponseToState } from './utils.tsx';

export type Actions =
	| LoadIssuesSuccessAction
	| ChangeIssueFieldValueAction
	| PageChangedAction
	| SortOrderChangedAction
	| ResetStateAction
	| FilterBadQueryAction;

const DEFAULT_ISSUES_STATE = {
	collection: [],
	index: {},
	totalCount: 0,
} as const;

const resetIssuesState = () => ({
	...DEFAULT_ISSUES_STATE,
	timeWhenLastInitialized: performance.now(),
});

// @ts-expect-error - TS2314 - Generic type 'Reducer' requires 1 type argument(s). | TS1016 - A required parameter cannot follow an optional parameter.
const reducer: Reducer<Issues, Actions> = (state?: Issues, action: Actions) => {
	if (state === undefined) {
		return resetIssuesState();
	}
	switch (action.type) {
		case RESET_STATE: {
			return resetIssuesState();
		}
		case PAGE_CHANGED: {
			return chain(state)
				.assoc('collection', [])
				.assoc('index', {})
				.assoc('timeWhenLastInitialized', performance.now())
				.value();
		}

		case IS_FILTER_FAILED: {
			return chain(state).assoc('totalCount', 0).value();
		}

		case LOAD_ISSUES_SUCCESS: {
			// This is here because setting the mark in a side effect
			// doesn't guarantee this is fired straight away.
			if (!getMark('jsd.performance.profile.queues.issues.loaded')) {
				setMark('jsd.performance.profile.queues.issues.loaded');
			}
			const {
				startIndex,
				loadedIssuesResponse: { totalCount, isUsingDefaultSorting },
				loadedIssuesResponse: { issues },
			} = action.payload;

			return addIssuesResponseToState(startIndex, totalCount, isUsingDefaultSorting, issues, state);
		}
		case CHANGE_ISSUE_FIELD_VALUE: {
			const { issueKey, columnId, newValue } = action.payload;
			const issueIndex: number = state.index[issueKey];
			return updateIn(state, ['collection'], (issueCollection: CollectionItem[]) => {
				// TODO FREE-2659 - unfortunately need to ensure the index entry wasn't stale, restructure issue object array + index -> a store where issues can be accessed by key
				const issue = issueCollection[issueIndex];
				if (issue && issue.key === issueKey) {
					const newIssueValue: Issue = updateIn(issue, ['fields', columnId], (storedValue) =>
						merge(storedValue, newValue),
					);
					return chain(issueCollection).set(issueIndex, newIssueValue).value();
				}
				return issueCollection;
			});
		}
		case SORT_ORDER_CHANGED: {
			return assoc(state, 'timeWhenLastInitialized', performance.now());
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
export default reducer;
